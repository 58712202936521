/** @jsx jsx */
import HomePageLayout from '../../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import Text_Image from '../../components/raw_templates/Text_Image';
import Text_calltoaction from '../../components/raw_templates/Text_calltoaction';
import { Row, Col } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Ankaufsprozess from '../../components/ReusableSections/Ankaufsprozess';

export default function SoFunktioniertEs({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      description="Einfach wie noch nie eine Immobilie verkaufen!"
      image={data.hero.childImageSharp.fluid}
      title="So funktioniert's"
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={false}
        orientation="southEast"
        variant="alpha"
        title="Einfach wie noch nie"
        subtitle="eine Immobilie verkaufen!"
        image={data.hero}
      />

      <Ankaufsprozess />

      <Text_Image
        textLeft
        background="muted"
        sx={{ padding: 0, textAlign: 'center' }}
        image={data.fuenfSchritten}
        title="<strong>In 5 Schritten zum erfolgreichen Hausankauf</strong>"
        subtitle="Einfach, schnell, diskret und sicher."
        text="<p>Wir kaufen Ihr Haus – für Sie einfach und bequem vom Sofa aus. Wir zeigen Ihnen in 5 Schritten, wie es geht.</p>"
        buttonText='Mehr erfahren <i class="fal fa-arrow-right"></i>'
        buttonLink="/haus-verkaufen/in-fuenf-schritten-zum-erfolgreichen-hausverkauf/"
      />

      <Text_Image
        sx={{ padding: 0, textAlign: 'center' }}
        background="white"
        image={data.onlineImmobilienrechner}
        title="<strong>Online bewerten</strong> und <strong>Geld sparen</strong>."
        subtitle="Einfach wie <strong>noch nie</strong> eine Immobilie <strong>bewerten</strong>"
        text="<p>Nutzen Sie zur Erstorientierung die kostenlose Online Immobilienbewertung von
WirkaufendeinHaus.at.<br/>
Finden Sie schnell und einfach heraus, wie der Wert Ihres Haus am Immobilienmarkt
eingeschätzt wird – kostenlos und ohne Registrierung!</p>"
        buttonText='Online Immobilienbewertung jetzt starten <i class="fal fa-arrow-right"></i>'
        buttonLink="/online-immobilienbewertung/"
      />

      <Text_Image
        textLeft={true}
        background="muted"
        sx={{ padding: 0, textAlign: 'center' }}
        image={data.terminVereinbaren}
        title="<strong>Sie möchten wissen, welchen Kaufpreis wir Ihnen für Ihr Haus anbieten?</stromg>"
        subtitle="Dann vereinbaren Sie jetzt einen kostenlosen und unverbindlichen Beratungstermin!"
        text="<p>Unsere regionalen Immobilienexperten stehen Ihnen auf Wunsch gerne in einem persönlichen Besichtigungstermin vor Ort beratend zur Seite.</p>"
        buttonText='Jetzt Termin vereinbaren <i class="fal fa-arrow-right"></i>'
        buttonLink="/terminbuchung/"
      />

      <Text_Image
        sx={{ padding: 0, textAlign: 'center' }}
        textLeft={false}
        background="white"
        image={data.rechtlicheAbwicklung}
        title="Die rechtliche Abwicklung beim Ankauf Ihres Hauses"
        text="<p>Lehnen Sie sich zurück! WirkaufendeinHaus.at übernimmt das für Sie.</p>"
        buttonText='Mehr erfahren <i class="fal fa-arrow-right"></i>'
        buttonLink="/haus-verkaufen/rechtliche-abwicklung/"
      />

      <Text_Image
        textLeft={true}
        background="muted"
        sx={{ padding: 0, textAlign: 'center' }}
        image={data.individuelleBeratung}
        title="Individuelle Beratung"
        subtitle="von <strong>Menschen</strong> für <strong>Menschen</strong>"
        text="<p>Individuelle Beratung wird bei WirkaufendeinHaus.at groß geschrieben.<br/>Bei uns ist Ihr
Anliegen rund um das Thema Immobilie in den besten Händen.</p>"
        buttonText='Entdecken Sie unser Leistungspaket <i class="fal fa-arrow-right"></i>'
        buttonLink="/haus-verkaufen/besichtigung-und-individuelle-beratung/"
      />

      <Text_Image
        textLeft={false}
        background="white"
        sx={{ padding: 0, textAlign: 'center' }}
        image={data.wertindikation}
        title="Wertindikation & Bewertungsgutachten"
        subtitle="So viel ist ihr Haus wert!"
        text="<p>Die richtige Einschätzung Ihres Immobilienwerts gibt Ihnen Sicherheit beim Verkauf Ihres
Hauses.</p>"
        buttonText='Mehr erfahren <i class="fal fa-arrow-right"></i>'
        buttonLink="/haus-verkaufen/wertindikation-bewertungsgutachten/"
      />

      <Text_Image
        sx={{ padding: 0, textAlign: 'center' }}
        textLeft={true}
        image={data.uebersiedlungsservice}
        background="muted"
        title="Sie brauchen Hilfe beim Umzug?"
        subtitle="Wir nehmen Ihnen die Arbeit ab!"
        text="<p>Mit unserem maßgeschneiderten Übersiedelungsservice finden wir garantiert die passende
Lösung für Ihren Umzug.</p>"
        buttonText='Jetzt Angebot einholen <i class="fal fa-arrow-right"></i>'
        buttonLink="/haus-verkaufen/uebersiedelungsservice/"
      />
    </HomePageLayout>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-sofunktionierts" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fuenfSchritten: file(name: { eq: "overview-fuenf-schritten" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 651, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    individuelleBeratung: file(name: { eq: "hero-individuelle-beratung" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 651, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    onlineImmobilienrechner: file(
      name: { eq: "echte-online-immobilienrechner" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 651, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    wertindikation: file(name: { eq: "hero-wertindikation" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 651, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rechtlicheAbwicklung: file(name: { eq: "hero-rechtliche-abwicklung" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 651, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mietoption: file(name: { eq: "hero-mietoption" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 651, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    uebersiedlungsservice: file(name: { eq: "hero-uebersiedlungsservice" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 651, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    terminVereinbaren: file(name: { eq: "termin-vereinbaren" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 851, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
