/** @jsx jsx */
import { jsx } from 'theme-ui';
import Text_calltoaction from '../raw_templates/Text_calltoaction';

export default function Ankaufsprozess() {
  return (
    <Text_calltoaction
      sectionBackground="white"
      title="Ihr <strong>Haus</strong> - Ihre <strong>Regeln</strong>"
      subtitle="So unkompliziert und transparent funktioniert unser Ankaufsprozess"
      buttons={[
        {
          text: 'Mehr erfahren <i class="fal fa-arrow-right"></i>',
          to: '/haus-verkaufen/ihr-haus-ihre-regeln-schneller-hausankauf/',
        },
      ]}
    />
  );
}
